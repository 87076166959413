
.account-item {
  display: inline-block;
  // align-items: center;
  // gap: 3px;
  margin-left: 20px;
  img {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    // background-color: black;
    object-fit: contain;
    vertical-align: middle;
    margin-right: 3px;
    // background-color: black;
  }
}

.live-broadcast {
  padding-block: 24px;

  .ellipses {
    white-space: nowrap; /* 禁止换行 */
    overflow: hidden; /* 超出容器部分隐藏 */
    text-overflow: ellipsis; /* 超出部分用省略号代替 */
  }

  .refresh {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(37, 38, 50, 0.6);
    line-height: 16px;
    cursor: pointer;
  }

  .item {
    margin-bottom: 16px;

    .item-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &_title {
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 18px;
        color: #252632;
        display: flex;
        align-items: center;
        gap: 5px;

        .el-icon-question {
          margin-left: 3px;
          width: 14px;
          height: 14px;
          font-size: 14px;
          font-style: normal;
          color: #bbbbbd;
          cursor: pointer;
        }
      }

      &_end {
        display: flex;
        align-items: center;
        gap: 20px;

        ::v-deep .el-select-dropdown.is-multiple .el-select-dropdown__item.selected .account-item span {
          color: #409eff;
        }

        ::v-deep .el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
          left: 20px;
          top: 0;
        }

        .filter-box {
          display: flex;
          align-items: center;
          gap: 12px;

          .filter-item {
            background-color: #f2f2f4;
            border-radius: 4px;
            padding: 2px 12px;

            span {
              color: rgba(37, 38, 50, 0.6);
            }

            ::v-deep .el-select__tags-text {
              max-width: 180px !important;
              flex-wrap: nowrap;
              display: inline-block;
              max-width: 100px !important; // 根据实际情况调整
              overflow: hidden; // 溢出隐藏
              text-overflow: ellipsis; // 超出文本以省略号显示
              white-space: nowrap; // 文本不换行
            }

            ::v-deep .el-input--suffix {
              width: 212px;
            }

            ::v-deep .el-input__inner {
              padding-left: 10px;
              background: transparent;
              border: none;
            }

            ::v-deep .el-date-editor .el-range-input {
              background-color: transparent;
            }

            ::v-deep .el-date-editor--daterange.el-input,
            .el-date-editor--daterange.el-input__inner,
            .el-date-editor--timerange.el-input,
            .el-date-editor--timerange.el-input__inner {
              width: 250px;
            }
          }
        }
      }

      &_operation {
        ::v-deep .el-button {
          padding: 6px 12px;
          background-color: #f2f2f4;
          border: none;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #777880;
          line-height: 16px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }
    }
  }

  .live-no-data {
    min-height: calc(100vh - 450px);
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateX(-200px);

    span {
      margin-top: 20px;
      font-family: PingFang SC, PingFang SC;
      font-size: 16px;
      font-weight: 600;
      color: #25263299;
    }
  }

  .container-box {
    min-height: calc(100vh - 350px);
    .account-column {
      display: flex;
      align-items: center;
      gap: 3px;

      .account-img {
        width: 46px;
        height: 46px;
        object-fit: cover;
        border-radius: 50%;
      }

      span {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #252632;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }

    .title-time-column {
      display: flex;
      align-items: center;
      gap: 5px;

      .title-time-img {
        width: 46px;
        height: 46px;
        object-fit: contain;
        border-radius: 8px;
      }

      .title-time-info {
        display: flex;
        flex-direction: column;

        span.popover-span {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #252632;
          text-align: left;
          font-style: normal;
          text-transform: none;
          cursor: pointer;
          display: inline-block;
          width: 170px;
        }

        .info-time {
          span,
          i {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            max-width: 41.5%;
            color: rgba(37, 38, 50, 0.6);
            text-align: left;
            font-style: normal;
          }

          & .icon-label {
            margin-left: 5px;
            color: #252632;
            padding: 1px 3px;
            border: 1px solid #f2f2f4;
          }
        }
      }
    }

    ::v-deep(.el-scrollbar__bar.is-horizontal) {
      height: 0px;
    }

    ::v-deep(.el-scrollbar__bar.is-vertical) {
      width: 0px;
    }

    ::v-deep .el-table__fixed,
    ::v-deep .el-table__fixed-right {
      pointer-events: none;
    } /* 表格固定列-鼠标事件穿透 */
    ::v-deep .el-table__fixed td,
    ::v-deep .el-table__fixed-right td,
    ::v-deep .el-table__fixed th,
    ::v-deep .el-table__fixed-right th {
      pointer-events: auto;
    }

    ::v-deep(.el-table__fixed-right::before),
    ::v-deep(.el-table__fixed::before) {
      background-color: transparent;
    }

    ::v-deep(.el-table td.el-table__cell),
    ::v-deep(.el-table th.el-table__cell.is-leaf) {
      border-bottom: none;
    }

    /* 自定义横向滚动条样式 */
    ::v-deep(.el-table__body-wrapper::-webkit-scrollbar) {
      height: 10px;
    }

    ::v-deep(.el-table__body-wrapper::-webkit-scrollbar-track) {
      // background: black;
      height: 10px;
    }

    ::v-deep(.el-table__body-wrapper::-webkit-scrollbar-thumb) {
      display: none;
      background: #dadbdd;
      border-radius: 5px;

      &:hover {
        display: block;
      }
    }

    ::v-deep(.custom-scrollbar.el-scrollbar:hover .el-table__body-wrapper::-webkit-scrollbar-thumb) {
      display: block;
    }
  }

  .medio-load {
    display: flex;
    justify-content: center;
    padding: 20px 0 0 0;

    span {
      font-weight: 400;
      font-size: 16px;
      color: #888888;
      line-height: 19px;
      cursor: pointer;
    }
  }
}
